<template>
    <div>
        <div v-for="(item, index) in data.items" :key="index" class="body" :style="{
            backgroundImage: 'url(' + item.cover + ')',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        }">
            <div class="title">
                {{ item.title }}
            </div>
            <div class="description">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log(this.data);
  }
};
</script>

<style lang="scss" scoped>
.body {
    width: 100%;
    height: 350px;
    text-align: center;
    color: #fff;
    margin-top: 30px;
}

.title {
    font-size: 48px;
    padding-top: 62px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.description {
    max-width: 850px;
    line-height: 36px;
    font-size: 20px;
    margin: 50px auto 0 auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

@media screen and (max-width: 700px) {

    .body {
        height: 200px;
    }

    .title {
        font-size: 26px;
        line-height: 48px;
        padding-top: 20px;
        font-weight: normal;
    }

    .description {
        line-height: 20px;
        max-width: 850px;
        font-size: 16px;
        padding: 0 40px;
        margin: 0 auto;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }
}
</style>
